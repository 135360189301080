import moment from "moment";
import React, { FunctionComponent, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";

import { ArrowBackIcon, CheckMarkIcon } from "../../../../assets";
import { Icon } from "../../../../components/Icon";
import { Input } from "../../../../components/Input/Input";
import { Modal, useModal } from "../../../../components/Modal/Modal.component";
import { AlertsPage } from "../../alerts/layout/AlertsPage";
import { Table, TableRow } from "../../../components/Table/Table";
import { Alert } from "../../../../types/alerts";
import { useAlerts } from "../../alerts/AlertsPageContainer";
import * as Historic from "./historic";
import { MemberEvent } from "../../../../lib/utils/member-event.utils";
import { Chart } from "../../../../lib/utils/chart";
import { Timeline } from "./components";
import EditCattleModal from "./edit";
import { useServices } from "../../../../services/service-providers/service-provider";
import { useHistory } from "react-router-dom";
import NANDI_URL from "../../../../lib/url";
import ActivityChartContainer from "./ActivityChartContainer";
import ActivityChartComponent from "./ActivityChartContainer/ActivityChartComponent";
import DailyCattleJumpBullContainer from "./DailyCattleJumpBullContainer";
import DailyBullJumpsContainer from "./DailyBullJumpsContainer/DailyBullJumps.Container";
import { User, UserRole } from "../../../../types/auth";
import MemberProfilePicture from "./MemberProfilePicture";
import MemberProfilePicturesModal from "./MemberProfilePicturesModal";
import classNames from "classnames";

type CattleMemberPageProps = {
  member: MemberWithDetails;
  alerts?: Array<Alert> | null;
  weightEvents: Array<MemberEvent> | null;
  vaccinationEvents: Array<MemberEvent> | null;
  dailyBullJumps: DailyActivityV2 | null;
  deltaActivityV2: DailyActivity | null;
  deltaActivityV3: DailyActivity | null;
  dailyCattleJumpBull: DailyCattleJumpBull | null;
  paddockAssignmentEvents: Array<MemberEvent> | null;
  sensorPairingEvents: Array<MemberEvent> | null;
  pregnancyEvents: Array<MemberEvent> | null;
  contactWithCowAggrEvents: Array<MemberEvent> | null;
  contactWithBullAggrEvents: Array<MemberEvent> | null;
  timeline: Timeline | null;
  user: User | null;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Label: FunctionComponent<{ value: ReactNode; label: ReactNode }> = ({
  value,
  label,
}) => {
  if (!value) {
    return null;
  }
  return (
    <p className="flex p-2 flex-col items-center">
      <span className="font-semibold">{value}</span>
      <span className="mt-1 text-gray-500">{label}</span>
    </p>
  );
};

const HistoryButton = ({
  text,
  iconName,
  onClick,
  iconCustomClassName,
}: {
  text?: any;
  iconName: string;
  onClick: () => void;
  iconCustomClassName?: string;
}) => {
  return (
    <>
      <div
        className="shadow p-2 flex flex-col items-center rounded cursor-pointer"
        onClick={onClick}
      >
        <Icon
          className={classNames(
            iconCustomClassName
              ? iconCustomClassName
              : "fill-current text-pink-600"
          )}
          iconName={iconName}
        />
        {text ? <span className="mt-2 hover:text-pink-600">{text}</span> : null}
      </div>
    </>
  );
};

const CattleMemberPage: FunctionComponent<CattleMemberPageProps> = ({
  member,
  alerts,
  weightEvents,
  vaccinationEvents,
  deltaActivityV2,
  deltaActivityV3,
  dailyBullJumps,
  dailyCattleJumpBull,
  paddockAssignmentEvents,
  sensorPairingEvents,
  pregnancyEvents,
  contactWithCowAggrEvents,
  contactWithBullAggrEvents,
  timeline,
  user,
}) => {
  const [editIsLoading, setEditIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { membersService } = useServices();
  const history = useHistory();
  const [chartOnDisplay, setChartOnDisplay] = useState(
    deltaActivityV2 && Object.keys(deltaActivityV2).length > 0
      ? "steps-v3"
      : "timeline"
  );

  const profilePictureModal = useModal();
  const vaccinesHistoryModal = useModal();
  const weightsHistoryModal = useModal();
  const paddockAssignmentModal = useModal();
  const sensorPairingModal = useModal();
  const pregnancyModal = useModal();
  const alertsH = useAlerts();
  const handleProfileImageOnClick = () => {
    profilePictureModal.setIsVisible(true);
  };

  const asyncActionWithConfirm = async (
    action: () => Promise<any>,
    confirmLabel: string
  ) => {
    if (user?.role === UserRole.SUPERUSER) {
      if (window.confirm(confirmLabel)) {
        await action()
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            throw Error(err);
          });
      }
    }
  };

  const handleRemoveGpsData = async () => {
    await asyncActionWithConfirm(
      () => membersService.removePositionData(member.id),
      t(`admin.cattlePage.attributes.removeLastPosition.confirm`)
    );
  };

  const handleTurnOffAlerts = async () => {
    await asyncActionWithConfirm(
      () => membersService.turnOffAlertsByMemberId(member.id),
      t(`admin.cattlePage.attributes.turnOffAlerts.confirm`)
    );
  };

  const handleTurnOnAlerts = async () => {
    await asyncActionWithConfirm(
      () => membersService.turnOnAlertsByMemberId(member.id),
      t(`admin.cattlePage.attributes.turnOnAlerts.confirm`)
    );
  };

  // refactor from here
  const [expandedEvent, setExpandedEvent] = useState<{
    memberEvent: MemberEvent;
    memberEventAttributes: MemberEventAttribute[];
  } | null>(null);
  const [expandedRow, setExpandedRow] = useState<number>(-1);

  const handleRowOnClose = () => {
    setExpandedEvent(null);
    setExpandedRow(-1);
  };

  const handleRowOnClick = async (mEvent: MemberEvent) => {
    setExpandedRow(mEvent.id);
    setExpandedEvent({
      memberEvent: mEvent,
      memberEventAttributes: mEvent.attributes,
    });
  };

  const handleModalClose = () => {
    setExpandedEvent(null);
    setExpandedRow(-1);
  };

  const handleVaccineModalClose = () => {
    handleModalClose();
    vaccinesHistoryModal.setIsVisible(false);
  };

  const handleWeightModalClose = () => {
    handleModalClose();
    weightsHistoryModal.setIsVisible(false);
  };

  const handlePregnancyModalClose = () => {
    handleModalClose();
    pregnancyModal.setIsVisible(false);
  };

  const handleLoadNewSensor = async (sensorName: string) => {
    setEditIsLoading(true);
    await membersService
      .pairNewSensorToMember(member.id, sensorName, member.account_id)
      .then(() => {
        if (window.confirm("Cambios satisfactorios")) {
          setEditIsLoading(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        if (window.confirm("Algo salió mal, por favor intente más tarde")) {
          setEditIsLoading(false);
        }
        throw new Error(err);
      });
  };
  const handleNext = async () => {
    const requestNextCatlle = await membersService.getNextMember(member.id);
    const nextMemberId = requestNextCatlle.nextMemberId;
    if (nextMemberId) {
      history.push(NANDI_URL.ADMIN.GENERATE_CATTLE_BY_ID(String(nextMemberId)));
    }
  };

  const handleEditMember = async (params: { [key: string]: string }) => {
    setEditIsLoading(true);
    await membersService
      .updateMember(member.id, params)
      .then(() => {
        if (window.confirm("Cambios satisfactorios")) {
          setEditIsLoading(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        if (window.confirm("Algo salió mal, por favor intente más tarde")) {
          setEditIsLoading(false);
        }
        throw new Error(err);
      });
  };

  const isExpanded = expandedRow !== -1;

  return (
    <>
      {profilePictureModal.isVisible && member.profile_picture ? (
        <MemberProfilePicturesModal
          onClose={() => {
            profilePictureModal.setIsVisible(false);
          }}
          member={member}
        />
      ) : null}
      {vaccinesHistoryModal.isVisible && (
        <Modal onClose={handleVaccineModalClose}>
          <Historic.Main
            displayButtons={false}
            onAdd={() => console.log("adding")}
            onClose={handleVaccineModalClose}
          >
            <Historic.Title>Vacunas</Historic.Title>
            {vaccinationEvents && !isExpanded ? (
              <Table
                className="mt-4"
                columns={Historic.vaccinationTableConstants()}
                items={vaccinationEvents}
                renderTableRow={false}
              >
                {vaccinationEvents.map((vaccinationEvent) => (
                  <TableRow
                    key={vaccinationEvent.id}
                    onClick={() => handleRowOnClick(vaccinationEvent)}
                  >
                    {Historic.vaccinationTableConstants().map((col, key) => (
                      <td key={key}>{col.render(vaccinationEvent)}</td>
                    ))}
                  </TableRow>
                ))}
              </Table>
            ) : null}
            {isExpanded ? (
              <section>
                <ArrowBackIcon
                  className="absolute top-4 left-4 cursor-pointer w-[24px] h-[24px] fill-current text-gray-400"
                  onClick={handleRowOnClose}
                />
                {expandedEvent?.memberEvent ? (
                  <>
                    <Historic.Attribute
                      label={t(`admin.cattlePage.attributes.vaccines.VACCINE`)}
                      value={expandedEvent.memberEvent.value}
                    />
                    <Historic.Attribute
                      label={t(`admin.cattlePage.attributes.vaccines.DATE`)}
                      value={moment
                        .unix(expandedEvent.memberEvent.ts)
                        .format("DD/MM/YYYY")}
                    />
                  </>
                ) : null}
                {expandedEvent?.memberEventAttributes.map(
                  ({ attribute, value }) => (
                    <Historic.Attribute
                      label={t(
                        `admin.cattlePage.attributes.vaccines.${attribute}`
                      )}
                      value={value}
                    />
                  )
                )}
              </section>
            ) : null}
          </Historic.Main>
        </Modal>
      )}
      {weightsHistoryModal.isVisible && (
        <Modal onClose={handleWeightModalClose}>
          <Historic.Main
            displayButtons={false}
            onAdd={() => console.log("adding")}
            onClose={handleWeightModalClose}
          >
            <Historic.Title>Pesajes</Historic.Title>
            {weightEvents && !isExpanded ? (
              <Table
                className="mt-4"
                columns={Historic.weightTableConstants()}
                items={weightEvents}
                renderTableRow={false}
              >
                {weightEvents.map((weightEvent) => (
                  <TableRow
                    key={weightEvent.id}
                    onClick={() => handleRowOnClick(weightEvent)}
                  >
                    {Historic.weightTableConstants().map((col, key) => (
                      <td key={key}>{col.render(weightEvent)}</td>
                    ))}
                  </TableRow>
                ))}
              </Table>
            ) : null}
            {isExpanded ? (
              <section>
                <ArrowBackIcon
                  className="absolute top-4 left-4 cursor-pointer w-[24px] h-[24px] fill-current text-gray-400"
                  onClick={handleRowOnClose}
                />
                {expandedEvent?.memberEvent ? (
                  <>
                    <Historic.Attribute
                      label={t(`admin.cattlePage.attributes.weights.WEIGHT`)}
                      value={expandedEvent.memberEvent.value}
                    />
                    <Historic.Attribute
                      label={t(`admin.cattlePage.attributes.weights.DATE`)}
                      value={moment
                        .unix(expandedEvent.memberEvent.ts)
                        .format("DD/MM/YYYY")}
                    />
                  </>
                ) : null}
                {expandedEvent?.memberEventAttributes.map(
                  ({ attribute, value }) => (
                    <Historic.Attribute
                      label={t(
                        `admin.cattlePage.attributes.weights.${attribute}`
                      )}
                      value={value}
                    />
                  )
                )}
              </section>
            ) : null}
          </Historic.Main>
        </Modal>
      )}
      {paddockAssignmentModal.isVisible && (
        <Modal onClose={() => paddockAssignmentModal.setIsVisible(false)}>
          <Historic.Main
            onAdd={() => console.log("adding")}
            onClose={() => paddockAssignmentModal.setIsVisible(false)}
          >
            <Historic.Title>Potreros</Historic.Title>
            {paddockAssignmentEvents ? (
              <Table
                className="mt-4"
                columns={Historic.paddocksTableConstants()}
                items={paddockAssignmentEvents}
              />
            ) : null}
          </Historic.Main>
        </Modal>
      )}
      {sensorPairingModal.isVisible && (
        <Modal onClose={() => sensorPairingModal.setIsVisible(false)}>
          <Historic.Main
            onAdd={() => console.log("adding")}
            onClose={() => sensorPairingModal.setIsVisible(false)}
            displayButtons={false}
          >
            <Historic.Title>Sensores</Historic.Title>
            {sensorPairingEvents ? (
              <Table
                className="mt-4"
                columns={Historic.sensorPairingTableConstants()}
                items={sensorPairingEvents}
              />
            ) : null}
          </Historic.Main>
        </Modal>
      )}
      {member.member_type === "COW" && pregnancyModal.isVisible && (
        <Modal onClose={handlePregnancyModalClose}>
          <Historic.Main
            onAdd={() => console.log("adding")}
            onClose={handlePregnancyModalClose}
            displayButtons={false}
          >
            <Historic.Title>Testeos de Preñez</Historic.Title>
            {pregnancyEvents && !isExpanded ? (
              <Table
                className="mt-4"
                columns={Historic.pregnancyTableConstants()}
                items={pregnancyEvents}
                renderTableRow={false}
              >
                {pregnancyEvents.map((event) => (
                  <TableRow
                    key={event.id}
                    onClick={() => handleRowOnClick(event)}
                  >
                    {Historic.pregnancyTableConstants().map((col, key) => (
                      <td key={key}>{col.render(event)}</td>
                    ))}
                  </TableRow>
                ))}
              </Table>
            ) : null}
            {isExpanded ? (
              <section>
                <ArrowBackIcon
                  className="absolute top-4 left-4 cursor-pointer w-[24px] h-[24px] fill-current text-gray-400"
                  onClick={handleRowOnClose}
                />
                {expandedEvent?.memberEvent ? (
                  <>
                    <Historic.Attribute
                      label={t(
                        `admin.cattlePage.attributes.pregnancy.PREGNANCY`
                      )}
                      value={expandedEvent.memberEvent.value}
                    />
                    <Historic.Attribute
                      label={t(`admin.cattlePage.attributes.pregnancy.DATE`)}
                      value={moment
                        .unix(expandedEvent.memberEvent.ts)
                        .format("DD/MM/YYYY")}
                    />
                  </>
                ) : null}
                {expandedEvent?.memberEventAttributes.map(
                  ({ attribute, value }) => (
                    <Historic.Attribute
                      label={t(
                        `admin.cattlePage.attributes.pregnancy.${attribute}`
                      )}
                      value={value}
                    />
                  )
                )}
              </section>
            ) : null}
          </Historic.Main>
        </Modal>
      )}
      <main className="p-4">
        <section className="grid grid-cols-8 gap-4">
          <section className="col-span-8 flex justify-evenly">
            <section>
              <MemberProfilePicture
                member={member}
                handleProfileImageOnClick={handleProfileImageOnClick}
              />
              <div className="flex mt-1  items-center">
                <span className="text-gray-500">EID: </span>
                <span className="font-semibold">{member.tag_id}</span>
                {member.tag_id && member.tag_id != "" ? (
                  <CheckMarkIcon className="inline ml-1 fill-current text-green-600" />
                ) : (
                  <CheckMarkIcon className="inline ml-1 fill-current text-yellow-600" />
                )}
                {member.status === "HEAT" ? (
                  <p
                    className="bg-red-700 rounded p-2 pt-1 pb-1 text-white"
                    title="This means this cattle unit is currently in heat."
                  >
                    Cow in Heat
                  </p>
                ) : null}
              </div>
            </section>
            <section>
              <div className="flex relative">
                <section className="pl-4 pr-4 w-full m-auto grid grid-cols-3 gap-2 content-center">
                  <Label
                    label="Birth Date"
                    value={moment(member.birth_date).format("YYYY/MM/DD")}
                  />
                  <Label label="Breed" value={member.breed} />
                  <Label label="Cattle Type" value={member.member_type} />
                </section>
                <button
                  onClick={handleNext}
                  className="flex cursor-pointer relative whitespace-nowrap text-center h-7 px-11 justify-center align-center bg-red-600 text-white text-lg font-bold rounded-2xl"
                >
                  Go To Next
                </button>
              </div>
              <section className="pl-4 pr-4 w-full m-auto mt-4 grid grid-cols-6 gap-2 content-center">
                <HistoryButton
                  text="Vaccines"
                  iconName="vaccine"
                  onClick={() => vaccinesHistoryModal.setIsVisible(true)}
                />
                {member.weight ? (
                  <HistoryButton
                    text={`${member.weight} kgs`}
                    iconName="weight"
                    onClick={() => weightsHistoryModal.setIsVisible(true)}
                  />
                ) : null}
                <HistoryButton
                  text={member.sensors?.name}
                  iconName="sensor"
                  onClick={() => sensorPairingModal.setIsVisible(true)}
                />
                {member.member_type === "COW" ? (
                  <HistoryButton
                    text="Pregnancy"
                    iconName="medical"
                    onClick={() => pregnancyModal.setIsVisible(true)}
                  />
                ) : null}
                {user?.role === UserRole.SUPERUSER && (
                  <EditCattleModal
                    handleLoadNewSensor={(sensorName: string) =>
                      handleLoadNewSensor(sensorName)
                    }
                    editIsLoading={editIsLoading}
                    memberTagId={member?.tag_id ?? ""}
                    memberETagId={member?.e_tag_id ?? ""}
                    handleEditMember={handleEditMember}
                  />
                )}
                {user?.role === UserRole.SUPERUSER ? (
                  <HistoryButton
                    text={"delete GPS data"}
                    iconName="position"
                    onClick={() => handleRemoveGpsData()}
                  />
                ) : null}
                {user?.role === UserRole.SUPERUSER && member.is_alertable ? (
                  <HistoryButton
                    text={"Turn off alerts"}
                    iconName="alerts"
                    onClick={() => handleTurnOffAlerts()}
                  />
                ) : null}
                {user?.role === UserRole.SUPERUSER &&
                member.is_alertable === false ? (
                  <HistoryButton
                    text={"Turn on alerts"}
                    iconName="alerts"
                    iconCustomClassName="fill-current text-gray-300"
                    onClick={() => handleTurnOnAlerts()}
                  />
                ) : null}
                {member.e_tag_id ? (
                  <HistoryButton
                    text={member.e_tag_id}
                    iconName="e_tag_id"
                    onClick={() => {}}
                  />
                ) : null}
              </section>
            </section>
          </section>
          <section className="col-span-8">
            <header>
              <Input
                onChange={(e) => {
                  setChartOnDisplay(e.target.value);
                }}
                elementType="select"
                elementConfig={Chart.getAvailableChartsPerCattle(
                  member,
                  t,
                  user?.role === UserRole.SUPERUSER
                )}
                value={chartOnDisplay}
              />
            </header>
            {member.member_type === "BULL" &&
            dailyCattleJumpBull &&
            Object.keys(dailyCattleJumpBull).length > 0 &&
            chartOnDisplay === "contacts-with-cattle" ? (
              <div className="mt-8">
                <DailyCattleJumpBullContainer
                  dailyCattleJumpBull={dailyCattleJumpBull}
                />
              </div>
            ) : null}

            {member.member_type === "BULL" &&
            dailyBullJumps &&
            Object.keys(dailyBullJumps).length > 0 &&
            chartOnDisplay === "daily-bull-jumps" ? (
              <div className="mt-8">
                <DailyBullJumpsContainer dailyBullJumps={dailyBullJumps} />
              </div>
            ) : null}
            {chartOnDisplay === "steps" &&
            deltaActivityV2 &&
            Object.keys(deltaActivityV2).length > 0 ? (
              <ActivityChartComponent
                labels={Object.keys(deltaActivityV2)}
                datasets={[
                  {
                    label: "Activity",
                    data: Object.values(deltaActivityV2),
                    backgroundColor: "rgba(75, 192, 192, 0.5)",
                  },
                ]}
              />
            ) : null}
            {chartOnDisplay === "steps-v3" &&
            deltaActivityV3 &&
            Object.keys(deltaActivityV3).length > 0 ? (
              <ActivityChartComponent
                labels={Object.keys(deltaActivityV3)}
                datasets={[
                  {
                    label: "Activity",
                    data: Object.values(deltaActivityV3),
                    backgroundColor: "rgba(75, 192, 192, 0.5)",
                  },
                ]}
              />
            ) : null}

            {chartOnDisplay === "contacts-with-cattle" &&
            member.member_type === "BULL" &&
            contactWithCowAggrEvents &&
            contactWithCowAggrEvents.length > 0 ? (
              <Bar
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }}
                className="mt-4 max-h-[350px]"
                data={{
                  labels: Chart.getAtoBDates().formated,
                  datasets: MemberEvent.generateContactsWithCattleDataset(
                    contactWithCowAggrEvents,
                    Chart.getAtoBDates().timestamps
                  ),
                }}
              />
            ) : null}
            {chartOnDisplay === "contacts-with-cattle" &&
            member.member_type === "COW" &&
            contactWithBullAggrEvents &&
            contactWithBullAggrEvents.length > 0 ? (
              <Line
                className="mt-4 max-h-[350px]"
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  elements: {
                    point: {
                      radius: 7,
                    },
                  },
                }}
                data={{
                  labels: Chart.getAtoBDates().formated,
                  datasets: MemberEvent.generateContactsWithCattleDataset(
                    contactWithBullAggrEvents,
                    Chart.getAtoBDates().timestamps
                  ),
                }}
              />
            ) : null}
            {chartOnDisplay === "alerts" && alerts ? (
              <AlertsPage
                title={alertsH.title}
                noAlertsMessage={alertsH.noAlertsMessage}
                alertsTableColumns={alertsH.alertsTableColumns}
                alerts={alerts}
              />
            ) : null}
            {chartOnDisplay === "timeline" && timeline ? (
              <Timeline className="mt-4 p-4" timeline={timeline} />
            ) : null}
          </section>
        </section>
      </main>
    </>
  );
};

export { CattleMemberPage };
