import { User } from "./auth";

export class AlertEvent {
  note: string;
  date: Date;
  status: string;
  alert_id: string;
  user_id: string;
  id: string;
  user: User | null;

  constructor(jsonAlert: any) {
    Object.assign(this, jsonAlert);
    this.date = new Date(jsonAlert.date);
  }
}

export class Alert {
  id: string;
  field_id: number;
  field_name: string;
  entity_type: AlertEntityType;
  entity_id: number;
  alert_type: AlertType;
  entity_description: string;
  date: Date;
  last_updated_date: Date;
  is_read: boolean;
  current_status: string;
  deleted: boolean;
  field: {
    name: string;
  };
  member: {
    tag_id: string;
    e_tag_id: string;
    sensors: {
      battery: number;
    };
  };

  constructor(jsonAlert: any) {
    Object.assign(this, jsonAlert);
    this.id = String(jsonAlert.id);
    this.date = new Date(jsonAlert.date);
    this.last_updated_date = new Date(jsonAlert.last_updated_date);
  }
}
export class AlertsRunResult {
  id: number;
  account_id: number;
  type: AlertType;
  processDate: string;
  created: string;
  renewed: string;
  resolved: string;
}

export enum AlertEntityType {
  BULL = "BULL",
  COW = "COW",
  DRINKER = "DRINKER",
  AREA = "AREA",
  FIELD = "FIELD",
}

export enum AlertType {
  RUN_AWAY = "Run Away",
  NO_RECEPTION = "No reception",
  CATTLE_WITHOUT_EVENTS_24_HS = "CATTLE_WITHOUT_EVENTS_24_HS",
  CATTLE_WITHOUT_EVENTS_48_HS = "CATTLE_WITHOUT_EVENTS_48_HS",
  CATTLE_LOW_ACTIVITY_24_HS = "CATTLE_LOW_ACTIVITY_24_HS",
}

export const sortAlertsByTime = (x: Alert, y: Alert) => {
  if (x.date > y.date) return 1;
  else return -1;
};

export const sortAlertsAlphabetically = (a: Alert, b: Alert) => {
  if (a.alert_type < b.alert_type) {
    return -1;
  }
  if (b.alert_type < a.alert_type) {
    return 1;
  }
  return 0;
};
