import L, { LatLngTuple, Layer } from "leaflet";
import React, { FunctionComponent } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import { useHistory } from "react-router";
import { Property } from "../../types/fields";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import { NDVIControl } from "./components";
import classNames from "classnames";

const MapTileLayer = () => (
  <TileLayer
    attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
  />
);

const getAlertColor = () => "red";

const getHeatColor = () => "brown";

const getMarkerColor = (type: string) => (type === "BULL" ? "blue" : "pink");

var getGeojsonMarkerOptions = (
  feature: GeoJSON.Feature<GeoJSON.Point, Property>
) => {
  return {
    radius: 2,
    fillColor:
      feature.properties.status === "HEAT"
        ? getHeatColor()
        : getMarkerColor(feature.properties.type),
    color: "#000",
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
  };
};

type MapComponentProps = {
  geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
  tiles?: Array<string>;
  ndviOptions?: {
    value: boolean;
    disabled: boolean;
    onClick: (e: any) => void;
  };
  sidePanel?: React.ReactNode;
  styleOptions?: {
    rounded: boolean;
  };
};

const Map: FunctionComponent<MapComponentProps> = ({
  geoJsonData,
  tiles,
  ndviOptions,
  sidePanel,
  styleOptions = {
    rounded: false,
  },
}) => {
  const history = useHistory();

  const featuresBounds = L.geoJSON(geoJsonData).getBounds();
  let mapCenter: LatLngTuple = [-38.572672, -62.052659];
  if (
    !(
      featuresBounds &&
      Object.keys(featuresBounds).length === 0 &&
      featuresBounds.constructor === Object
    )
  ) {
    const center = featuresBounds.getCenter();
    mapCenter = [center.lat, center.lng];
  }

  const antennaIcon = L.icon({
    iconUrl: "./antenna.png",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const WaterDropIcon = L.icon({
    iconUrl: "./water-drop.svg",
    iconSize: [16, 16],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const onEachField = (
    feature: GeoJSON.Feature<GeoJSON.Polygon, Property>,
    layer: Layer
  ) => {
    const tooltip = L.tooltip({ permanent: false });
    tooltip.setContent(feature.properties.display);
    layer.bindTooltip(tooltip).openTooltip();
    if (feature.properties.type === "WATER") {
      (layer as any).options.color = "#00B5E2";
      (layer as any).options.fillColor = "#00B5E2";
      (layer as any).options.radius = 4;
    } else if (feature.properties.type === "ANTENNA") {
      (layer as any).options.color = "yellow";
      (layer as any).options.fillColor = "yellow";
    } else {
      (layer as any).options.fillColor = "transparent";
    }

    layer.on({
      click: () => {
        if (feature.properties.onClick) feature.properties.onClick(history);
      },
    });
  };

  return (
    <div className="flex w-full h-full">
      <MapContainer
        key={mapCenter.toString()}
        center={mapCenter}
        zoom={14}
        zoomControl={false}
        scrollWheelZoom={true}
        className={classNames("w-full h-full", {
          "rounded-xl": styleOptions.rounded,
        })}
        whenCreated={(map) => {
          if (
            !(
              featuresBounds &&
              Object.keys(featuresBounds).length === 0 &&
              featuresBounds.constructor === Object
            )
          ) {
            map.fitBounds(featuresBounds);
          }
        }}
      >
        {tiles?.map((tile, index) => (
          <TileLayer key={index} url={tile} />
        ))}
        <MapTileLayer />
        <GeoJSON
          pointToLayer={(feature, latLang) => {
            if (feature.properties.type === "ANTENNA") {
              return L.marker(latLang, { icon: antennaIcon });
            } else if (feature.properties.type === "WATER") {
              return L.marker(latLang, { icon: WaterDropIcon });
            } else {
              return L.circleMarker(latLang, getGeojsonMarkerOptions(feature));
            }
          }}
          key={JSON.stringify(geoJsonData)}
          onEachFeature={onEachField}
          style={{
            color: "white",
            weight: 1,
          }}
          data={geoJsonData}
        />
        <FullscreenControl />
        {ndviOptions ? (
          <NDVIControl
            value={ndviOptions.value}
            onClick={ndviOptions.onClick}
            disabled={ndviOptions.disabled}
          />
        ) : null}
        {sidePanel ? (
          <section className="absolute bottom-0 top-0 z-[10000] right-[10px]">
            {sidePanel}
          </section>
        ) : null}
      </MapContainer>
    </div>
  );
};

export const EnhancedMap = Map;
