import React, { ChangeEvent, FunctionComponent, useState } from "react";
// Context
import { useMember } from "../../../../../context/member";
// Components
import { Icon } from "../../../../../components/Icon";
import {
  Modal,
  useModal,
} from "../../../../../components/Modal/Modal.component";
import { Image } from "../../../../../components/Image/Image";
import Tab, { Tabs } from "../../../../../components/Tab";
import request from "../../../../../services/request";
import { Button } from "../../../../../components/Button/Button";

type Props = {
  handleLoadNewSensor: (newSensor: string) => void;
  handleEditMember: (params: { [key: string]: string }) => void;
  editIsLoading: boolean;
  memberTagId: string;
  memberETagId: string;
};

type AvailableTabs =
  | "FIRST_PHOTO"
  | "SECOND_PHOTO"
  | "PAIR_SENSOR"
  | "EDIT_CARAVANA"
  | "EDIT_E_CARAVANA";

const EditCattleModal: FunctionComponent<Props> = ({
  handleLoadNewSensor,
  handleEditMember,
  editIsLoading,
  memberTagId,
  memberETagId,
}) => {
  const {
    state: { member, isLoading },
    dispatch: memberDispatch,
  } = useMember();
  const editModal = useModal();
  const [selectedTab, setSelectedTab] = useState<AvailableTabs>("FIRST_PHOTO");
  const [newSensor, setNewSensor] = useState<string>("");
  const [tagId, setTagId] = useState(memberTagId);
  const [eTagId, setETagId] = useState(memberETagId);

  const handleTagId = (e: ChangeEvent<HTMLInputElement>) => {
    setTagId(e.target.value);
  };

  const handleETagId = (e: ChangeEvent<HTMLInputElement>) => {
    setETagId(e.target.value);
  };

  const handleNewSensor = (e: ChangeEvent<HTMLInputElement>) => {
    setNewSensor(e.target.value);
  };
  const onChange = (e: any, side: string) => {
    let file = e.target.files[0];
    uploadFile(file, side);
  };

  const uploadFile = async (file: any, side: string) => {
    let formData = new FormData();
    formData.append("file", file);
    request({
      method: "POST",
      url: `/api/members/${member?.id}/update_profile_pic`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        side,
      },
    })
      .then((r) => {
        let result = window.confirm(
          "Upload successfull, refresh to see changes?"
        );
        if (result) {
          window.location.reload();
        }
      })
      .catch((err) => {
        alert("There was an error uploading the image");
      });
  };

  return (
    <>
      <div
        className="shadow p-2 flex flex-col items-center rounded cursor-pointer"
        onClick={() => {
          editModal.setIsVisible(true);
        }}
      >
        <Icon className="fill-current text-pink-600" iconName={"edit"} />
        <span className="mt-2 hover:text-pink-600">Edit</span>
      </div>
      {editModal.isVisible ? (
        <Modal
          onClose={() => {
            editModal.setIsVisible(false);
          }}
        >
          <section className="bg-white p-2 rounded-lg min-w-[750px] min-h-[400px]">
            <header>
              <p className="font-semibold">{member?.tag_id}</p>
              <div className="min-w-[750px]">
                <Tabs>
                  <Tab
                    label="First Photo"
                    onClick={() => {
                      setSelectedTab("FIRST_PHOTO");
                    }}
                    selected={selectedTab === "FIRST_PHOTO"}
                  />
                  <Tab
                    label="Second Photo"
                    onClick={() => {
                      setSelectedTab("SECOND_PHOTO");
                    }}
                    selected={selectedTab === "SECOND_PHOTO"}
                  />
                  <Tab
                    label="Pair sensor"
                    onClick={() => {
                      setSelectedTab("PAIR_SENSOR");
                    }}
                    selected={selectedTab === "PAIR_SENSOR"}
                  />
                  <Tab
                    label="Edit caravana"
                    onClick={() => {
                      setSelectedTab("EDIT_CARAVANA");
                    }}
                    selected={selectedTab === "EDIT_CARAVANA"}
                  />
                  <Tab
                    label="Edit E-caravana"
                    onClick={() => {
                      setSelectedTab("EDIT_E_CARAVANA");
                    }}
                    selected={selectedTab === "EDIT_E_CARAVANA"}
                  />
                </Tabs>
              </div>
            </header>
            {selectedTab === "FIRST_PHOTO" ? (
              <section className="mt-2">
                <p>Change 1st Photo</p>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onChange(e, "base")}
                />
                {member?.profile_picture ? (
                  <Image
                    className="w-full mt-2 max-w-[600px] h-full max-h-[400px]"
                    imageLink={member.profile_picture}
                    alt={member.tag_id}
                  />
                ) : (
                  <p>No 1st Photo</p>
                )}
              </section>
            ) : null}

            {selectedTab === "SECOND_PHOTO" ? (
              <div className="mt-2">
                <p>Change 2nd Photo</p>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onChange(e, "front")}
                />
                {member?.profile_picture_front ? (
                  <Image
                    className="w-full mt-2 max-w-[600px] h-full max-h-[400px]"
                    imageLink={member.profile_picture_front}
                    alt={member.tag_id}
                  />
                ) : (
                  <p>No 2nd Photo</p>
                )}
              </div>
            ) : null}
            {selectedTab === "PAIR_SENSOR" ? (
              <div className="mt-8">
                <form className="flex flex-col gap-4 justify-start h-full">
                  <div className="flex gap-2">
                    <p>Mac address:</p>
                    <input
                      className="border-[1px] border-solid border-black rounded-md w-[80%]"
                      onChange={handleNewSensor}
                      value={newSensor}
                    />
                  </div>
                  <Button
                    onClick={() => handleLoadNewSensor(newSensor)}
                    disabled={newSensor === ""}
                    isLoading={editIsLoading}
                  >
                    Load
                  </Button>
                </form>
              </div>
            ) : null}
            {selectedTab === "EDIT_CARAVANA" ? (
              <div className="mt-8">
                <form className="flex flex-col gap-4 justify-start h-full">
                  <div className="flex gap-2">
                    <p>Caravana:</p>
                    <input
                      className="border-[1px] border-solid border-black rounded-md w-[80%]"
                      onChange={handleTagId}
                      value={tagId}
                    />
                  </div>
                  <Button
                    onClick={() => handleEditMember({ tag_id: tagId })}
                    disabled={tagId === "" || tagId === memberTagId}
                    isLoading={editIsLoading}
                  >
                    Edit
                  </Button>
                </form>
              </div>
            ) : null}
            {selectedTab === "EDIT_E_CARAVANA" ? (
              <div className="mt-8">
                <form className="flex flex-col gap-4 justify-start h-full">
                  <div className="flex gap-2">
                    <p>E-caravana:</p>
                    <input
                      className="border-[1px] border-solid border-black rounded-md w-[80%]"
                      onChange={handleETagId}
                      value={eTagId}
                    />
                  </div>
                  <Button
                    onClick={() => handleEditMember({ e_tag_id: eTagId })}
                    disabled={eTagId === "" || eTagId === memberETagId}
                    isLoading={editIsLoading}
                  >
                    Edit
                  </Button>
                </form>
              </div>
            ) : null}
          </section>
        </Modal>
      ) : null}
    </>
  );
};

export default EditCattleModal;
