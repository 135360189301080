import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
// Components
import { Loader } from "../../../components/Loader/Loader";
import { EnhancedMap } from "../../../components/Map/Map";
import { PageTitle } from "../../../components/PageTitle/PageTitle.component";
// Services
import { useServices } from "../../../services/service-providers/service-provider";
import { Alert } from "../../../types/alerts";
import {
  createGeoJsonData,
  Property,
  FieldSummaryWithExtraDetails,
  NdviData,
} from "../../../types/fields";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import AlertsMonitor from "../../components/AlertsMonitor";
import { MapReference } from "./layout/components";
import { HealthCalendar } from "../../components/HealthCalendar";
import { CreateSanitaryReportModal } from "../../components/CreateSanitaryReportModal";
import moment from "moment";
import {
  CreateSanitaryReportDto,
  NewSanitaryReportData,
} from "../../../types/sanitary-reports";
import { ReducedInventoryMonitor } from "../../components/ReducedInventoryMonitor";

const groupArrayOfObjects = (list: Array<MemberWithDetails>) => {
  const groups: {
    ALERT: Array<MemberWithDetails>;
    HEAT: Array<MemberWithDetails>;
    NORMAL: Array<MemberWithDetails>;
    COWS: Array<MemberWithDetails>;
    BULLS: Array<MemberWithDetails>;
  } = {
    ALERT: [],
    HEAT: [],
    NORMAL: [],
    COWS: [],
    BULLS: [],
  };

  list.forEach((member) => {
    if (member.status === "ALERT") groups.ALERT.push(member);
    if (member.status === "HEAT") groups.HEAT.push(member);
    if (member.status === "NORMAL") {
      groups.NORMAL.push(member);
      // only add to cow/ bulls group to those in regular state
      if (member.member_type === "COW") groups.COWS.push(member);
      if (member.member_type === "BULL") groups.BULLS.push(member);
    }
  });

  return groups;
};

type State = {
  field?: FieldSummaryWithExtraDetails;
  geoJsonData?: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
  alerts: Array<Alert>;
  members?: Array<MemberWithDetails>;
  membersByStatus?: {
    ALERT: Array<MemberWithDetails>;
    HEAT: Array<MemberWithDetails>;
    NORMAL: Array<MemberWithDetails>;
    COWS: Array<MemberWithDetails>;
    BULLS: Array<MemberWithDetails>;
  };
};

const PaddockByIdV2Container: FunctionComponent<
  WithLoaderProps & { paddockId: string; selectedAccount: any }
> = ({ paddockId, isLoading, setLoading, selectedAccount }) => {
  const { t } = useTranslation();
  const { fieldsService, sanitaryReportsService } = useServices();
  const [state, setState] = useState<State>({ alerts: [] });
  const [openReportModal, setOpenReportModal] = useState<boolean>(false);
  // Filters of map
  const [ndviFilter, setNdviFilter] = useState(false);
  const [nvdiData, setNvdiData] = useState<NdviData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        state.field?.ndvi_tile &&
          setNvdiData(
            await fieldsService.getNvdiByTile(state.field?.ndvi_tile)
          );
      } catch (error) {
        // Maneja los errores aquí
        console.error("Error al obtener datos:", error);
      }
    };
    fetchData();
  }, [state.field]);

  const loadInfo = async () => {
    setLoading(true);
    const fieldInfo = await fieldsService.getFieldByIdWithMembersInventory(
      paddockId
    );

    const alertsInfo = await fieldsService.getFieldAlerts(paddockId);

    const geoJsonInfo = createGeoJsonData({
      fields: fieldInfo ? [fieldInfo] : undefined,
      ref: "admin",
      members: fieldInfo.members,
    });
    const membersGroupedByStatus = groupArrayOfObjects(fieldInfo.members);

    setState({
      field: fieldInfo,
      geoJsonData: geoJsonInfo,
      alerts: alertsInfo,
      members: fieldInfo.members,
      membersByStatus: membersGroupedByStatus,
    });
    setLoading(false);
  };

  useEffect(() => {
    loadInfo();
    return () => {};
  }, []);

  const handleNDVIFilter = () => {
    setNdviFilter((prevNdviFilter) => !prevNdviFilter);
  };

  // New sanitary report
  const newReportInitData: () => NewSanitaryReportData = () => {
    return {
      date: moment().format("YYYY-MM"),
      accountable: "",
      detail: "",
    };
  };

  const [newReportData, setNewReportData] = useState<NewSanitaryReportData>(
    newReportInitData()
  );

  const handleChangeNewReportData = (
    event: React.ChangeEvent<{ name?: unknown; value: unknown }>
  ) => {
    setNewReportData({
      ...newReportData,
      [`${event.target.name}`]: event.target.value,
    });
  };

  const handleSubmitSanitaryReport = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (selectedAccount && selectedAccount.id) {
      const ts = moment(newReportData.date, "YYYY-MM").unix();
      const newSanitaryReport: CreateSanitaryReportDto = {
        accountId: Number(selectedAccount.id),
        accountableTo: newReportData.accountable,
        detail: newReportData.detail,
        creationDate: ts,
        fieldId: Number(paddockId),
      };
      await sanitaryReportsService
        .create(newSanitaryReport)
        .then((res) => {
          setOpenReportModal(false);
        })
        .catch((err) => {
          setOpenReportModal(false);
          throw new Error(err);
        });
    }
  };

  return (
    <Loader isLoading={isLoading}>
      <section className="p-4 bg-[#F2F2F7]">
        <PageTitle>
          {t("admin.paddockPage.title", { paddockName: state.field?.name })}
        </PageTitle>

        <div className="w-full flex justify-end">
          {/*           <button
            className="bg-[#6668A4] hover:bg-[#2F3080] px-4 py-2 text-white rounded-xl"
            onClick={() => setOpenReportModal(true)}
          >
            {t("monitorPage.newSanitaryReport.title")}
          </button> */}
          <CreateSanitaryReportModal
            handleCancel={() => setOpenReportModal(false)}
            handleChange={handleChangeNewReportData}
            openModal={openReportModal}
            setOpenModal={setOpenReportModal}
            handleSubmit={handleSubmitSanitaryReport}
          />
        </div>

        <main className="grid grid-cols-4 lg:grid-cols-12 gap-4 mt-4 auto-rows-max marker">
          <section className="col-span-6 lg:col-span-8 grid grid-cols-4 lg:grid-cols-12 gap-4">
            <ErrorBoundary>
              <AlertsMonitor
                className="col-span-4 lg:col-span-12 rounded-xl"
                selectedAccount={selectedAccount}
                alertsPerPaddock={true}
                fieldId={paddockId}
              />
            </ErrorBoundary>
            {state.members && state.field ? (
              <ErrorBoundary>
                <div className="col-span-4 lg:col-span-12 flex w-full">
                  <HealthCalendar
                    selectedAccount={selectedAccount}
                    className="w-full"
                  />
                </div>
              </ErrorBoundary>
            ) : null}
          </section>
          <section className="col-span-3 lg:col-span-4 min-h-[550px] flex flex-col gap-6">
            {state.members && state.field ? (
              <ErrorBoundary>
                <ReducedInventoryMonitor
                  members={state.members}
                  fields={[state.field]}
                  className="col-span-4 lg:col-span-12"
                />
              </ErrorBoundary>
            ) : null}
            {state.geoJsonData ? (
              <EnhancedMap
                geoJsonData={{
                  ...state.geoJsonData,
                }}
                tiles={
                  ndviFilter
                    ? nvdiData.map((field) =>
                        field.agro ? field.agro[0].tile.ndvi : ""
                      )
                    : undefined
                }
                ndviOptions={{
                  onClick: handleNDVIFilter,
                  value: ndviFilter,
                  disabled: nvdiData ? false : true,
                }}
                sidePanel={
                  state.membersByStatus ? (
                    <MapReference membersByStatus={state.membersByStatus} />
                  ) : null
                }
              />
            ) : null}
          </section>
        </main>
      </section>
    </Loader>
  );
};

const PaddockByIdV2Page = withLoaderHOC(PaddockByIdV2Container);

export { PaddockByIdV2Page };
