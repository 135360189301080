import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Loader } from "../../../components/Loader/Loader";
import { useMember } from "../../../context/member";
import { MemberEvent } from "../../../lib/utils/member-event.utils";
import { useServices } from "../../../services/service-providers/service-provider";
import { Alert } from "../../../types/alerts";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { CattleMemberPage } from "./layout/CattleMemberPage";
import { User } from "../../../types/auth";

const PageContainer: FunctionComponent<
  WithLoaderProps & {
    memberId: string;
  }
> = ({ memberId, isLoading, setLoading }) => {
  const [state, setState] = useState<{
    member: MemberWithDetails | null;
    alerts: Array<Alert> | null;
    weightEvents: Array<MemberEvent> | null;
    vaccinationEvents: Array<MemberEvent> | null;
    deltaActivityV2: DailyActivity | null;
    deltaActivityV3: DailyActivity | null;
    dailyCattleJumpBull: DailyCattleJumpBull | null;
    dailyBullJumps: DailyActivityV2 | null;
    paddockAssignmentEvents: Array<MemberEvent> | null;
    sensorPairingEvents: Array<MemberEvent> | null;
    pregnancyEvents: Array<MemberEvent> | null;
    contactWithCowAggrEvents: Array<MemberEvent> | null;
    contactWithBullAggrEvents: Array<MemberEvent> | null;
    timeline: Timeline | null;
    user: User | null;
  }>({
    member: null,
    alerts: null,
    weightEvents: null,
    vaccinationEvents: null,
    dailyBullJumps: null,
    deltaActivityV2: null,
    deltaActivityV3: null,
    dailyCattleJumpBull: null,
    paddockAssignmentEvents: null,
    sensorPairingEvents: null,
    pregnancyEvents: null,
    contactWithCowAggrEvents: null,
    contactWithBullAggrEvents: null,
    timeline: null,
    user: null,
  });
  const { membersService, timelineService, usersService } = useServices();
  const memberContext = useMember();

  const loadPage = async () => {
    setLoading(true);
    try {
      memberContext.dispatch({ type: "request" });
      const user = await usersService.getCurrentUser();
      const member = await membersService.getMemberByIdV2(Number(memberId));
      const alerts = await membersService.getMemberAlertsByIdV2(
        Number(memberId)
      );
      const memberEvents = await membersService.getMemberEventsDisk(memberId);

      const deltaActivityV2 = await membersService.getDeltaActivityByIdV2(
        Number(memberId)
      );

      const deltaActivityV3 = await membersService.getDeltaActivityByIdV3(
        Number(memberId)
      );

      const dailyCattleJumpBull = await membersService.getDailyCattleJumpBull(
        Number(memberId)
      );

      const dailyBullJumps = await membersService.getDailyBullJumpsCounter(
        Number(memberId)
      );
      const timeline = await timelineService.getByMemberId(Number(memberId));

      const vaccinationEvents = MemberEvent.getByType(
        memberEvents,
        "VACCINATION"
      ).sort(MemberEvent.sortByTs);

      const weightEvents = MemberEvent.getByType(memberEvents, "WEIGHT").sort(
        MemberEvent.sortByTs
      );

      const pregnancyEvents =
        member.member_type === "COW"
          ? MemberEvent.getByType(memberEvents, "PREGNANCY").sort(
              MemberEvent.sortByTs
            )
          : [];

      const paddockAssignmentEvents = MemberEvent.getByType(
        memberEvents,
        "PADDOCK_ASSIGNMENT"
      ).sort(MemberEvent.sortByTs);

      const sensorPairingEvents = MemberEvent.getByType(
        memberEvents,
        "SENSOR_PAIRING"
      ).sort(MemberEvent.sortByTs);

      const contactWithCowAggrEvents =
        member.member_type === "BULL"
          ? MemberEvent.getByType(memberEvents, "CONTACT_WITH_COW_AGGR").sort(
              MemberEvent.sortByTs
            )
          : null;

      const contactWithBullAggrEvents =
        member.member_type === "COW"
          ? MemberEvent.getByType(memberEvents, "CONTACT_WITH_BULL_AGGR").sort(
              MemberEvent.sortByTs
            )
          : null;

      memberContext.dispatch({ type: "success", member: member });
      setState({
        member,
        alerts,
        weightEvents,
        vaccinationEvents,
        deltaActivityV2,
        deltaActivityV3,
        dailyCattleJumpBull,
        dailyBullJumps,
        paddockAssignmentEvents,
        sensorPairingEvents,
        pregnancyEvents,
        contactWithCowAggrEvents,
        contactWithBullAggrEvents,
        timeline,
        user,
      });
      setLoading(false);
    } catch (e) {
      memberContext.dispatch({
        type: "failure",
        error: "Couldn't get the member",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPage();
  }, [memberId]);

  useEffect(() => {
    if (memberId) document.title = `Nandi | Cattle ${memberId}`;
  }, [memberId]);

  return (
    <Loader isLoading={isLoading}>
      {state.member ? (
        <CattleMemberPage
          member={state.member}
          alerts={state.alerts}
          dailyBullJumps={state.dailyBullJumps}
          weightEvents={state.weightEvents}
          vaccinationEvents={state.vaccinationEvents}
          deltaActivityV2={state.deltaActivityV2}
          deltaActivityV3={state.deltaActivityV3}
          dailyCattleJumpBull={state.dailyCattleJumpBull}
          paddockAssignmentEvents={state.paddockAssignmentEvents}
          sensorPairingEvents={state.sensorPairingEvents}
          pregnancyEvents={state.pregnancyEvents}
          contactWithCowAggrEvents={state.contactWithCowAggrEvents}
          contactWithBullAggrEvents={state.contactWithBullAggrEvents}
          timeline={state.timeline}
          user={state.user}
        />
      ) : null}
    </Loader>
  );
};

const CattleMemberPageContainer = withLoaderHOC(PageContainer);

export { CattleMemberPageContainer };
