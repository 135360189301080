import React, { FunctionComponent } from "react";

import styles from "./LoaderSpin.module.scss";

import loadingSpin from "../../assets/loader.gif";

interface LoaderSpinProps {}

export const LoaderSpin: FunctionComponent<LoaderSpinProps> = () => (
  <div className="mt-9">
    <img className={styles.loaderSpin} src={loadingSpin} alt="loading_spin" />
  </div>
);

export const LoaderSpinWithChildren: FunctionComponent<LoaderSpinProps> = ({
  children,
}) => (
  <>
    <img className={styles.loaderSpin} src={loadingSpin} alt="loading_spin" />
    {children}
  </>
);
