import { Alert } from "../types/alerts";
import request from "./request";
import { IMembersService } from "./services-types";
import { NextMemberResponse, OldMemberCount } from "../types/members";

export class ProductionMembersService implements IMembersService {
  getMemberById = async (memberId: number): Promise<MemberWithDetails> => {
    return await request({
      method: "get",
      url: `/api/members/${memberId}`,
      params: {
        include_details: true,
      },
    }).then((res) => {
      return {
        ...res,
        last_alert: res.last_alert ? new Alert(res.last_alert) : null,
      };
    });
  };

  getMemberByIdV2 = async (memberId: number): Promise<MemberWithDetails> => {
    return await request({
      method: "get",
      url: `/members/${memberId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    }).then((res) => {
      return res;
    });
  };

  getMemberAlertsByIdV2 = async (memberId: number): Promise<Array<Alert>> => {
    return await request({
      method: "get",
      url: `/members/${memberId}/alerts`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
  turnOffAlertsByMemberId = async (memberId: number): Promise<any> => {
    return await request({
      method: "post",
      url: `/members/${memberId}/turn-off-alerts`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  turnOnAlertsByMemberId = async (memberId: number): Promise<any> => {
    return await request({
      method: "post",
      url: `/members/${memberId}/turn-on-alerts`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getMemberAlerts = async (memberId: number): Promise<Array<Alert>> => {
    return await request({
      method: "get",
      url: `/api/members/${memberId}/alerts`,
    }).then((jsonAlerts) =>
      jsonAlerts.map((jsonAlert: any) => new Alert(jsonAlert))
    );
  };

  getMemberEvents = async (
    account_id: string,
    tag_id: string
  ): Promise<Array<any>> => {
    return await request({
      baseURL: "/",
      method: "get",
      url: `/member_events`,
      params: {
        account_id,
        tag_id,
      },
    });
  };

  pairNewSensorToMember = async (
    memberId: number,
    sensorName: string,
    accountId: number
  ): Promise<any> => {
    return await request({
      method: "patch",
      url: `/members/${memberId}/new-sensor`,
      data: {
        sensorName,
        accountId,
      },
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  editTagId = async (memberId: number, tagId: string): Promise<any> => {
    return await request({
      method: "patch",
      url: `/members/${memberId}`,
      params: {
        tag_id: tagId,
      },
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  updateMember = async (
    memberId: number,
    params: { [value: string]: string }
    // params values allowed: tag_id, e_tag_id
  ): Promise<any> => {
    return await request({
      method: "patch",
      url: `/members/${memberId}`,
      params,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getMemberEventsDisk = async (
    memberId: string | number
  ): Promise<Array<any>> => {
    return await request({
      method: "get",
      url: `/api/member-events/${memberId}`,
      params: {
        include_attributes: false,
      },
    });
  };

  getMemberEventAttributes = async (
    memberEventId: number
  ): Promise<Array<MemberEventAttribute>> => {
    return await request({
      method: "get",
      url: `/api/member-events/${memberEventId}/attributes`,
    });
  };

  getMemberEventsByDates = async (
    startDate?: number,
    endDate?: number
  ): Promise<
    Array<{ date: string; member_events: number; member_messages: number }>
  > => {
    // returns the last 7 days by default
    return await request({
      method: "get",
      url: "/member-events/count-by-day",
      params: {
        "start-date": startDate,
        "end-date": endDate,
      },
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
  getDeltaActivityByIdV2 = async (memberId: number): Promise<DailyActivity> => {
    return await request({
      method: "get",
      url: "/member-events/delta-activity-v2",
      params: {
        memberId,
      },
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getDeltaActivityByIdV3 = async (memberId: number): Promise<DailyActivity> => {
    return await request({
      method: "get",
      url: "/member-events/delta-activity-v3",
      params: {
        memberId,
      },
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getMemberEventsLastDay = async (): Promise<Array<MemberEventsLastDay>> => {
    return await request({
      method: "get",
      url: `/members/events-by-last-day`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getDailyActivityByMember = async (
    memberId: number
  ): Promise<DailyActivity> => {
    return await request({
      method: "get",
      url: `/member-events/daily-activity`,
      params: { memberId },
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getDailyCattleJumpBull = async (
    memberId: number
  ): Promise<DailyCattleJumpBull> => {
    return await request({
      method: "get",
      url: `/member-events/bull-jumps/`,
      params: { memberId },
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getDailyBullJumpsCounter = async (
    memberId: number
  ): Promise<DailyActivityV2> => {
    return await request({
      method: "get",
      url: `/member-events/bull-jumps-counter-v2/`,
      params: { memberId },
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  removePositionData = async (memberId: number): Promise<any> => {
    return await request({
      method: "PATCH",
      url: `/members/position-data`,

      params: { memberId },
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getMembers = async (
    filters: Array<{ key: string; value: string }>
  ): Promise<Array<MemberWithDetails>> => {
    return this.getMembersByType([], filters);
  };

  getMembersByType = async (
    memberType: Array<MemberType>,
    filters: Array<{ key: string; value: string }>
  ): Promise<Array<MemberWithDetails>> => {
    let params: { [key: string]: string } = {};
    filters.forEach(({ key, value }) => {
      params[key] = value;
    });

    if (memberType && memberType[0] && memberType[0] !== "ALL") {
      return await request({
        baseURL: process.env.REACT_APP_NEW_API_URL,
        method: "get",
        url: `/members`,
        params: {
          ...params,
          member_type: memberType[0],
          include_details: true,
        },
      });
    } else {
      return await request({
        baseURL: process.env.REACT_APP_NEW_API_URL,
        method: "get",
        url: `/members`,
        params: {
          ...params,
          include_details: true,
        },
      });
    }
  };

  createMembersByAccount = async (
    members: Array<MemberCreate>
  ): Promise<any> => {
    return await request({
      method: "post",
      data: members,
      url: "/api/members",
    });
  };

  createMemberEventsByMemberId = async (
    memberEvents: Array<MemberEventCreate>
  ): Promise<any> => {
    return await request({
      method: "post",
      data: memberEvents,
      url: "/member-events",
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getMembersSummary = async (): Promise<Array<OldMemberCount>> => {
    return await request({
      method: "get",
      url: `/api/members/summary`,
    });
  };

  getNextMember = async (memberId: number): Promise<NextMemberResponse> => {
    return await request({
      method: "GET",
      url: `/members/next/${memberId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
  removeMember = async (memberId: number): Promise<Member> => {
    return await request({
      method: "DELETE",
      url: `/members/${memberId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
}
