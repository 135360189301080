import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import * as XLSX from "xlsx";
import { Loader } from "../../../components/Loader/Loader";
import { PageTitle } from "../../../components/PageTitle/PageTitle.component";
import { isMsgError } from "../../../lib/error";
import { ServicesContext } from "../../../services/service-providers/service-provider";
import { RootState } from "../../../store/store";
import { UserRole } from "../../../types/auth";
import { FieldSummary } from "../../../types/fields";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import AccountCattle, { CattleSection } from "../../pages/cattle/layout";
import UploadCattle from "../../pages/cattle/layout/upload";

type CattlePageState = {
  rows: Array<UploadMember>;
  accountCattle: Array<MemberWithDetails> | null;
  fields: Array<FieldSummary> | null;
  selectedField: FieldSummary | null;
  selectedFieldId: number;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = WithLoaderProps & PropsFromRedux & WithTranslation;

const CattlePageContainer: FunctionComponent<Props> = ({
  user,
  t,
  selectedAccount,
  isLoading,
  setLoading,
  setError,
}) => {
  const { membersService, fieldsService } = useContext(ServicesContext);
  const [state, setState] = useState<CattlePageState>({
    rows: [],
    accountCattle: null,
    fields: null,
    selectedField: null,
    selectedFieldId: -1,
  });

  const loadPageInfo = async () => {
    setLoading(true);
    const fields = await fieldsService.getFieldsList(
      false,
      String(selectedAccount.id)
    );
    let params = [{ key: "account_id", value: String(selectedAccount.id) }];
    const members = await membersService.getMembers(params);
    const cattle = members.filter(
      (x) => x.member_type === "BULL" || x.member_type === "COW"
    );
    setState((prevState) => ({ ...prevState, accountCattle: cattle, fields }));
    setLoading(false);
  };

  const onFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files!![0];
    var reader = new FileReader();

    reader.onload = (e: any) => {
      var data = e.target.result;
      // there's a bug in here with parsing stuff with leading zeros
      var workbook = XLSX.read(data, {
        type: "binary",
        cellDates: true,
        dateNF: "yyyy-mm-dd",
      });

      workbook.SheetNames.forEach((sheetName) => {
        const json_object = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName]
        );
        //console.log(json_object);
        //console.log(json_object as UploadMember[]);
        setState((prevState) => ({
          ...prevState,
          rows: json_object as UploadMember[],
        }));
      });
    };

    reader.onerror = (ex) => {
      console.log(ex);
    };

    reader.readAsBinaryString(file);
  };

  const onUploadAnimals = async () => {
    const uploadArray: Array<MemberCreate> = state.rows.map((row) => ({
      ...row,
      birth_date: row.birth.getTime(),
      status: "NORMAL",
      account_id: selectedAccount.id,
    }));
    try {
      setLoading(true);
      const data = await membersService.createMembersByAccount(uploadArray);
      if (data) {
        setLoading(false);
      }
    } catch (error) {
      if (isMsgError(error)) setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMember = async (id: number, name: string) => {
    if (
      window.confirm(
        t("admin.allMembersPage.confirmRemove", { cattleName: name })
      )
    ) {
      setLoading(true);
      await membersService
        .removeMember(id)
        .then(() => {
          loadPageInfo();
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  };

  useEffect(() => {
    loadPageInfo();
  }, [selectedAccount.id]);

  const handlePaddockChange = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    let params = [{ key: "account_id", value: String(selectedAccount.id) }];

    let selectedFieldId = e.target.value;
    let selectedField = state.fields ? state.fields[e.target.value] : null;
    if (selectedField?.id) {
      params.push({
        key: "member_field_id",
        value: String(selectedField.id),
      });
    }
    const members = await membersService.getMembers(params);
    const cattle = members.filter(
      (x) => x.member_type === "BULL" || x.member_type === "COW"
    );
    setState((prevState) => ({
      ...prevState,
      accountCattle: cattle,
      selectedField,
      selectedFieldId,
    }));
    setLoading(false);
  };

  useEffect(() => {
    console.log("loading cattle page");
    document.title = `Nandi | Cattle`;
  }, []);

  return (
    <Loader isLoading={isLoading}>
      <CattleSection>
        <PageTitle className="mt-4">
          <>
            {t("admin.cattlePage.title", {
              accountName: selectedAccount.name,
            })}{" "}
            ({state.accountCattle?.length}){" "}
            <select
              onChange={handlePaddockChange}
              value={state.selectedFieldId}
            >
              <option value={-1} selected>
                Seleccionar Potrero
              </option>
              {state.fields?.map((field, id) => (
                <option key={field.id} value={id}>
                  {field.name}
                </option>
              ))}
            </select>
          </>
        </PageTitle>
        {state.accountCattle && state.accountCattle.length > 0 ? (
          <AccountCattle
            user={user}
            cattle={state.accountCattle}
            onDelete={(id: number, name: string) =>
              handleRemoveMember(id, name)
            }
          />
        ) : null}
        {user?.role === UserRole.SUPERUSER ? (
          <UploadCattle
            title={t("admin.cattlePage.upload.title")}
            cta={t("admin.cattlePage.upload.cta")}
            onFileInput={onFileHandler}
            uploadCattle={state.rows}
            onUploadAnimals={onUploadAnimals}
          />
        ) : null}
      </CattleSection>
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: state.accounts.selectedAccount,
    user: state.users.user,
  };
};

const connector = connect(mapStateToProps);

export default connector(withTranslation()(withLoaderHOC(CattlePageContainer)));
