import React, { FunctionComponent, useEffect, useState } from "react";

import {
  FieldSummaryWithExtraDetails,
  NdviData,
  Property,
} from "../../../../types/fields";

import { EnhancedMap } from "../../../../components/Map/Map";
import { PageTitle } from "../../../../components/PageTitle/PageTitle.component";

// v2 containers
import AlertsMonitor from "../../../components/AlertsMonitor";
import { useServices } from "../../../../services/service-providers/service-provider";
import { HealthCalendar } from "../../../components/HealthCalendar";
import { ReducedInventoryMonitor } from "../../../components/ReducedInventoryMonitor";
import { AccountAttribute } from "../../../types/Account";

type AdminHomePageProps = {
  title: string;
  fields: Array<FieldSummaryWithExtraDetails>;
  members: Array<MemberWithDetails>;
  geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
  selectedAccount: any;
  weatherData: AccountAttribute | null;
};

const AdminHomePageV2: FunctionComponent<AdminHomePageProps> = ({
  title,
  members,
  geoJsonData,
  fields,
  selectedAccount,
  weatherData,
}) => {
  const [ndviFilter, setNdviFilter] = useState(false);
  const [nvdiData, setNvdiData] = useState<NdviData[]>([]);

  const { fieldsService } = useServices();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const valoresDeTile = fields.map((tile) => tile.ndvi_tile);
        const tiles = valoresDeTile.join("|");
        setNvdiData(await fieldsService.getNvdiByTile(tiles));
      } catch (error) {
        // Maneja los errores aquí
        console.error("Error al obtener datos:", error);
      }
    };
    fetchData();
  }, []);

  const handleNDVIFilter = () => {
    setNdviFilter((prevNdviFilter) => !prevNdviFilter);
  };

  return (
    <div className="w-full h-auto p-4 bg-[#F2F2F7]">
      <PageTitle className="w-[fit-content]">{title}</PageTitle>

      <main className="grid grid-cols-4 lg:grid-cols-12 gap-4 mt-4 auto-rows-max auto-cols-max">
        <section className="col-span-6 lg:col-span-8 grid grid-cols-4 lg:grid-cols-12 gap-4">
          <AlertsMonitor
            className="col-span-4 lg:col-span-12 rounded-xl"
            selectedAccount={selectedAccount}
          />
          <div className="col-span-4 lg:col-span-12 flex w-full">
            <HealthCalendar
              selectedAccount={selectedAccount}
              className="w-full"
            />
          </div>
        </section>
        <section className="col-span-3 lg:col-span-4 min-h-[550px] flex flex-col gap-6  bg-white pt-4 p-4 rounded-lg border bg-card text-card-foreground shadow-sm">
          <ReducedInventoryMonitor
            climateData={weatherData}
            members={members}
            fields={fields}
            className="col-span-4 lg:col-span-12"
          />
          <EnhancedMap
            styleOptions={{ rounded: true }}
            geoJsonData={{
              ...geoJsonData,
            }}
            tiles={
              ndviFilter
                ? nvdiData.map((field) =>
                    field.agro ? field.agro[0].tile.ndvi : ""
                  )
                : undefined
            }
            ndviOptions={{
              onClick: handleNDVIFilter,
              value: ndviFilter,
              disabled: !nvdiData.some(
                (field) =>
                  field.agro && field.agro[0] && field.agro[0].tile.ndvi
              ),
            }}
          />
        </section>
      </main>
    </div>
  );
};

export { AdminHomePageV2 };
