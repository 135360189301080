import React, { FunctionComponent } from "react";

import { Bar } from "react-chartjs-2";

type Props = {
  pedometerEvents?: MemberEvent[];
  labels: string[];
  datasets: {
    data: number[];
    label: string;
    backgroundColor: string | string[];
  }[];
};

const ActivityChartComponent: FunctionComponent<Props> = ({
  labels,
  datasets,
}) => (
  <Bar
    options={{
      responsive: true,
      maintainAspectRatio: false,
    }}
    className="mt-4 max-h-[350px]"
    data={{
      labels: labels,
      datasets: datasets,
    }}
  />
);

export default ActivityChartComponent;
