import React, { FunctionComponent } from "react";
// Components
import DailyCattleJumpBullComponent from "./DailyCattleJumpBullComponent";
// Utils

type Props = {
  dailyCattleJumpBull: DailyCattleJumpBull | null;
};

const DailyCattleJumpBullContainer: FunctionComponent<Props> = ({
  dailyCattleJumpBull,
}) => {
  const generateColor = () => {
    const red = Math.floor(Math.random() * 255);
    const green = Math.floor(Math.random() * 255);
    const blue = Math.floor(Math.random() * 255);

    return `rgb(${red}, ${green}, ${blue})`;
  };

  const bullJumps: any =
    dailyCattleJumpBull !== null ? dailyCattleJumpBull : {};

  const nearbyCows = Object.keys(bullJumps);
  const datasets = nearbyCows.map((nearbyCow) => {
    const data = Object.values(bullJumps?.[nearbyCow]).map((x) =>
      x === 0 ? undefined : x
    );
    return {
      label: nearbyCow,
      data,
      backgroundColor: generateColor(),
      showLine: false,
    };
  });

  const dates = Object.keys(bullJumps?.[nearbyCows[0]] ?? []) ?? [];

  return dailyCattleJumpBull !== null &&
    Object.keys(dailyCattleJumpBull).length > 0 ? (
    <DailyCattleJumpBullComponent labels={dates} datasets={datasets} />
  ) : null;
};

export default DailyCattleJumpBullContainer;
