import React, { FC, useEffect, useState } from "react";

import { Modal } from "../../../../../components/Modal/Modal.component";
import { Image } from "../../../../../components/Image/Image";

import { useServices } from "../../../../../services/service-providers/service-provider";

interface Props {
  member: Member;
  onClose: () => void;
}

const MemberProfilePicturesModal: FC<Props> = ({ member, onClose }) => {
  const [profilePicture, setProfilePicture] = useState<string>();
  const [profilePictureFront, setProfilePictureFront] = useState<string>();
  const { storageService } = useServices();

  const loadProfileImages = async () => {
    if (member.profile_picture) {
      const profilePictureSignedUrl = await storageService.get(
        member.profile_picture
      );
      setProfilePicture(profilePictureSignedUrl);
    }

    if (member.profile_picture_front) {
      const profilePictureFrontSignedUrl = await storageService.get(
        member.profile_picture_front
      );
      setProfilePictureFront(profilePictureFrontSignedUrl);
    }
  };

  useEffect(() => {
    loadProfileImages();
  }, []);

  return (
    <Modal
      onClose={() => {
        onClose();
      }}
    >
      <main className="grid gap-2 bg-white p-4 rounded">
        {profilePicture ? (
          <Image
            className="w-full max-w-[600px] h-full max-h-[400px] object-cover"
            imageLink={profilePicture}
            alt={member.tag_id}
          />
        ) : null}
        {profilePictureFront ? (
          <Image
            className="w-full max-w-[600px] h-full max-h-[400px]"
            imageLink={profilePictureFront}
            alt={`${member.tag_id}_front`}
          />
        ) : null}
      </main>
    </Modal>
  );
};

export default MemberProfilePicturesModal;
