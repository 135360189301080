import React, { FC, useEffect, useState } from "react";

import { Image } from "../../../../../components/Image/Image";
import { useServices } from "../../../../../services/service-providers/service-provider";

const MemberProfilePicture: FC<{
  handleProfileImageOnClick: () => void;
  member: Member;
}> = ({ member, handleProfileImageOnClick }) => {
  const [profilePicture, setProfilePicture] = useState<string>();
  const { storageService } = useServices();

  const loadProfileImage = async () => {
    if (member.profile_picture) {
      const profilePictureSignedUrl = await storageService.get(
        member.profile_picture
      );
      setProfilePicture(profilePictureSignedUrl);
    }
  };

  useEffect(() => {
    loadProfileImage();
  }, []);

  return (
    <>
      {profilePicture ? (
        <div
          onClick={handleProfileImageOnClick}
          className="w-[150px] h-[150px] m-auto cursor-pointer"
        >
          <Image
            className="w-[150px] h-[150px] rounded-full"
            imageLink={profilePicture}
            alt={member.tag_id}
          />
        </div>
      ) : null}
    </>
  );
};

export default MemberProfilePicture;
